import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually

import './assets/scss/styles.scss'

Vue.config.productionTip = false

Vue.use(VueCroppie);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
